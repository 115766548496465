import moment from "moment";
import prettyMilliseconds from "pretty-ms";

export const formatDate = (date: Date) => {
  return moment(date).format("D-MMMM-YYYY hh:mm A");
};

export const formatDateForJustMonthDayAndYear = (date: Date) => {
  return moment(date).format("DD-MM-YY");
};
export const convertRawTransworldDateToActualDate = (dateString: string) => {
  const formattedDate: string = moment(dateString).format("DD MMM YYYY HH:mm");
  return formattedDate;
};
export const convertRawZalproDateToActualDate = (dateString: string) => {
  const actualDate = moment(dateString, "DD MMM YYYY HH:mm").format(
    "YYYY-MM-DD"
  );
  return actualDate;
};

export const getDifferenceOfDaysBetweenCurrentTimeAndInputDate = (
  dateString: string
) => {
  const date2 = new Date(dateString);
  const date1 = new Date();
  const date1Ms = date1.getTime();
  const date2Ms = date2.getTime();

  // Calculate the difference in milliseconds
  const differenceMs = date1Ms - date2Ms;

  // Convert the difference from milliseconds to days
  const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  return differenceDays;
};
export const getDifferenceOfDaysBetweenInputDateAndCurrentTime = (
  dateString: string
) => {
  const date1 = new Date(dateString);
  const date2 = new Date();
  const date1Ms = date1.getTime();
  const date2Ms = date2.getTime();

  // Calculate the difference in milliseconds
  const differenceMs = date1Ms - date2Ms;

  // Convert the difference from milliseconds to days
  const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  return differenceDays;
};

export const checkIfItsDDMMMYYYYFormatDate = (dateString: string) => {
  let lowerCaseDate = "";
  if (!dateString) {
    const currentDate = new Date();
    dateString = convertRawTransworldDateToActualDate(currentDate.toString());
    lowerCaseDate = dateString.toLowerCase();
  } else {
    lowerCaseDate = dateString.toLowerCase();
  }
  const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  const [day, month, year, time] = lowerCaseDate.split(" ");
  if (isNaN(parseInt(day, 10))) {
    return false;
  }
  if (Number(day) > 31) {
    return false;
  }
  if (!months.includes(month)) {
    console.log("month not found");
    return false;
  }
  if (isNaN(parseInt(year, 10))) {
    console.log("invalid year");
    return false;
  }
  if (Number(year) > 2050) {
    console.log("invalid year");
    return false;
  }
  if (Number(year) < 2000) {
    console.log("invalid year");
    return false;
  }
  const includesString = months.some((str) => lowerCaseDate.includes(str));
  return includesString;
};
export const parseDateForDDMMMYYYYFormatForTransworld = (date: string) => {
  const originalDate = moment(date, "DD MMM YYYY HH:mm").toDate();
  const formattedDate = moment(originalDate).format("YYYY-MM-DD");
  return formattedDate;
};
const getMMDDYYYYFormatDateUsingJavascript = (date: Date) => {
  // Extract the components of the date
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns zero-based months
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  // Concatenate the components into mm/dd/yyyy format
  const formattedDate = month + "/" + day + "/" + year;
  return formattedDate;
};
export const formatToYYYYMMDDFromTransworldRaw = (dateString: string) => {
  if (!dateString) {
    dateString = getMMDDYYYYFormatDateUsingJavascript(new Date());
  }
  const [date, time] = dateString.split(" ");
  if (!date) {
    return null;
  }
  const [_month, _date, _year] = date.split("/");
  if (!_month || !_date || !_year) {
    return null;
  }
  if (Number(_month) > 12) {
    return null;
  }
  return `${Number(_year) > 2000 ? _year : "20" + _year}-${
    Number(_month) < 10 && _month.length < 2 ? "0" + _month : _month
  }-${Number(_date) < 10 && _date.length < 2 ? "0" + _date : _date}`;
};
export const checkIfAllTheDataFollowDDMMMYYYYFormat = (data: any[]) => {
  const currentDate = new Date();

  let isFollow = true;
  for (const obj of data) {
    const expiry = obj.expiry
      ? obj.expiry
      : convertRawTransworldDateToActualDate(currentDate.toString());
    console.log({ expiry });
    const res = checkIfItsDDMMMYYYYFormatDate(expiry);
    if (!res) {
      isFollow = false;
    }
  }
  return isFollow;
};

export const checkIfAllTheDataFollowYYYYMMDDFormat = (data: any[]) => {
  let isFollow = true;
  const currentDate = new Date();

  for (const obj of data) {
    const expiry = obj.expiry
      ? obj.expiry
      : getMMDDYYYYFormatDateUsingJavascript(currentDate);
    console.log({ expiry });
    const res = formatToYYYYMMDDFromTransworldRaw(expiry);
    if (!res) {
      isFollow = false;
    }
  }
  return isFollow;
};
export const getTransworldFormattedDate = (
  expiry: string,
  formatType: number
) => {
  const currentDate = new Date();

  if (formatType === 1) {
    expiry = expiry
      ? expiry
      : convertRawTransworldDateToActualDate(currentDate.toString());
    return parseDateForDDMMMYYYYFormatForTransworld(expiry) || "";
  } else if (formatType === 2) {
    expiry = expiry
      ? expiry
      : getMMDDYYYYFormatDateUsingJavascript(currentDate);
    return formatToYYYYMMDDFromTransworldRaw(expiry) || "";
  }
  return ``;
};

export const calculateNextExpiryDate = (
  expiryDate: Date,
  duration: string
): string => {
  let newDate: moment.Moment;

  if (duration === "MONTHLY") {
    newDate = moment(expiryDate).add(1, "month");
  } else {
    newDate = moment(expiryDate).add(30, "days");
  }
  newDate.set({ hour: 23, minute: 59, second: 59 });

  return newDate.format("YYYY-MM-DD HH:mm:ss");
};
export const readableUpTime = (createdAt: string): string => {
  // Parse the createdAt timestamp to a moment object
  const createdAtMoment = moment(createdAt);

  // Get the current time as a moment object
  const now = moment();

  // Calculate the difference in seconds
  const differenceInSeconds = now.diff(createdAtMoment, "seconds");
  return prettyMilliseconds(Number(differenceInSeconds) * 1000);
};
