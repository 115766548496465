import { MdHome, MdRouter, MdDataUsage, MdPerson3 } from "react-icons/md";
import { FaFileCircleXmark } from "react-icons/fa6";
import { GrUserManager } from "react-icons/gr";
import { AiOutlineCloudServer, AiOutlineFileText } from "react-icons/ai";
import { GoOrganization } from "react-icons/go";
import { FiFileText, FiDownloadCloud } from "react-icons/fi";
import { PiUserSoundFill } from "react-icons/pi";
import { TbFileImport } from "react-icons/tb";
import { IoTicketOutline, IoBarChartSharp } from "react-icons/io5";
import { BsPersonWorkspace } from "react-icons/bs";
import { getUserType } from "./helpers/userDetails";
import ViewPackagesPageForDealer from "./pages/ViewPackagesPageForDealer";
import HomePage from "./pages/HomePage";
import ViewAllCompaniesPage from "./pages/ViewAllCompaniesPage";
import ViewCompanyRoutersBySuperAdminPage from "./pages/ViewCompanyRoutersBySuperAdminPage";
import ViewNasDetailsPage from "./pages/ViewNasDetailsPage";
import ViewRadiusProbesPage from "./pages/ViewRadiusProbesPage";
import ViewRadiusChildProbesPage from "./pages/ViewRadiusChildProbesPage";
import PPPoEUsersPage from "./pages/PPPoEUsersPage";
import ViewConnectedUsersPage from "./pages/ViewConnectedUsersPage";
import SyncSecretsPage from "./pages/SyncSecretsPage";
import ViewRoutersPage from "./pages/ViewRoutersPage";
import ViewPackagesPage from "./pages/ViewPackagesPage";
import ViewStaffPage from "./pages/ViewStaffPage";
import ViewAllDealersPage from "./pages/ViewAllDealersPage";
import DiscountVouchersPage from "./pages/DiscountVouchersPage";
import ViewAuditLogsPage from "./pages/ViewAuditLogsPage";
import ViewFailedAuthLogsPage from "./pages/ViewFailedAuthLogsPage";
import StaffProfilePage from "./pages/StaffProfilePage";
import ViewMyProfilePage from "./pages/ViewMyProfilePage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import CreatePPPoEUsersPage from "./pages/CreatePPPoEUsersPage";
import PPPoEUserProfilePage from "./pages/PPPoEUserProfilePage";
import ViewEndUserInvoiceSOAPage from "./pages/ViewEndUserInvoiceSOAPage";
import ViewDealerProfilePage from "./pages/ViewDealerProfilePage";
import DealerPaymentsReportPage from "./pages/DealerPaymentsReportPage";
import DealerLedgerReportPage from "./pages/DealerLedgerReportPage";
import CompanyRetailsSalesChartsPage from "./pages/CompanyRetailsSalesChartsPage";
import ImportPackagesPage from "./pages/ImportPackagesPage";
import MyRouterDetailsPage from "./pages/MyRouterDetailsPage";

const routesList: Array<Route> = [
  {
    path: "/",
    Component: HomePage,
    roles: ["ADMIN", "RMAN", "SUPER_ADMIN", "DEALER", "DEALER_RMAN"],
    text: "Home",
    show: true,
    Icon: MdHome,
  },
  {
    path: "/companies",
    Component: ViewAllCompaniesPage,
    roles: ["SUPER_ADMIN"],
    text: "Companies",
    show: true,
    Icon: GoOrganization,
  },
  {
    path: "/super-admin/company-routers-details/:intPkCompanyID",
    Component: ViewCompanyRoutersBySuperAdminPage,
    roles: ["SUPER_ADMIN"],
    text: "Routers",
    show: false,
    Icon: "",
  },
  {
    path: "/super-admin/company-routers/router-details/:intPkNasDeviceID/:intPkCompanyID",
    Component: ViewNasDetailsPage,
    roles: ["SUPER_ADMIN"],
    text: "Routers",
    show: false,
    Icon: "",
  },

  {
    path: "/probes",
    Component: ViewRadiusProbesPage,
    roles: ["SUPER_ADMIN"],
    text: "Probes",
    show: true,
    Icon: AiOutlineCloudServer,
  },
  {
    path: "/child-probes/:intPkMeshBillRadiusProbeID",
    Component: ViewRadiusChildProbesPage,
    roles: ["SUPER_ADMIN"],
    text: "Child Probes",
    show: false,
    Icon: "",
  },

  {
    path: "/pppoe-users",
    Component: PPPoEUsersPage,
    roles: ["ADMIN", "RMAN", "DEALER", "DEALER_RMAN"],
    text: "PPPoE Users",
    show: true,
    Icon: BsPersonWorkspace,
  },

  {
    path: "/pppoe-users/connected-users",
    Component: ViewConnectedUsersPage,
    roles: ["ADMIN", "RMAN", "DEALER", "DEALER_RMAN"],
    text: "Connected PPPoE Users",
    show: true,
    Icon: PiUserSoundFill,
  },
  {
    path: "/import-users",
    Component: SyncSecretsPage,
    roles: ["ADMIN"],
    text: "Import Users",
    show: true,
    Icon: TbFileImport,
  },
  {
    path: "/routers",
    Component: ViewRoutersPage,
    roles: ["ADMIN"],
    text: "Routers",
    show: true,
    Icon: MdRouter,
  },
  {
    path: "/routers/router-details/:intPkNasDeviceID",
    Component: MyRouterDetailsPage,
    roles: ["ADMIN"],
    text: "Routers",
    show: false,
    Icon: "",
  },
  {
    path: "/packages",
    Component: ViewPackagesPage,
    roles: ["ADMIN", "RMAN"],
    text: "Packages",
    show: true,
    Icon: MdDataUsage,
  },
  {
    path: "/import-packages",
    Component: ImportPackagesPage,
    roles: ["ADMIN"],
    text: "Import Packages",
    show: true,
    Icon: FiDownloadCloud,
  },
  {
    path: "/dealer-packages",
    Component: ViewPackagesPageForDealer,
    roles: ["DEALER", "DEALER_RMAN"],
    text: "Packages",
    show: true,
    Icon: MdDataUsage,
  },
  {
    path: "/staff",
    Component: ViewStaffPage,
    roles: ["ADMIN", "DEALER"],
    text: "Staff",
    show: true,
    Icon: MdPerson3,
  },
  {
    path: "/dealers",
    Component: ViewAllDealersPage,
    roles: ["ADMIN"],
    text: "Dealers",
    show: true,
    Icon: GrUserManager,
  },

  {
    path: "/discount-vouchers",
    Component: DiscountVouchersPage,
    roles: ["ADMIN", "DEALER"],
    text: "Vouchers",
    show: true,
    Icon: IoTicketOutline,
  },
  {
    path: "/audit-logs",
    Component: ViewAuditLogsPage,
    roles: ["ADMIN"],
    text: "Audit Logs",
    show: true,
    Icon: FiFileText,
  },
  {
    path: "/failed-auth-logs",
    Component: ViewFailedAuthLogsPage,
    roles: ["ADMIN"],
    text: "Failed Auth Logs",
    show: true,
    Icon: FaFileCircleXmark,
  },
  {
    path: "/staff-profile/:intPkHeadID",
    Component: StaffProfilePage,
    roles: ["ADMIN", "DEALER"],
    text: "Staff Profile",
    show: false,
    Icon: "",
  },
  {
    path: "/auth/profile",
    Component: ViewMyProfilePage,
    roles: ["ADMIN", "RMAN", "SUPER_ADMIN", "DEALER", "DEALER_RMAN"],
    text: "Staff Profile",
    show: false,
    Icon: "",
  },
  {
    path: "/auth/update-password",
    Component: ChangePasswordPage,
    roles: ["ADMIN", "RMAN", "SUPER_ADMIN", "DEALER", "DEALER_RMAN"],

    text: "Change Password",
    show: false,
    Icon: "",
  },
  {
    path: "/create-pppoe-user",
    Component: CreatePPPoEUsersPage,
    roles: ["ADMIN", "RMAN", "DEALER", "DEALER_RMAN"],
    text: "Create End User",
    show: false,
    Icon: "",
  },
  {
    path: "/pppoe-user-details/:intPkEndUserID",
    Component: PPPoEUserProfilePage,
    roles: ["ADMIN", "RMAN", "DEALER", "DEALER_RMAN"],
    text: "End User Profile",
    show: false,
    Icon: "",
  },
  {
    path: "/end-user-invoice-soa/:intPkEndUserID/:intPkEndUserInvoiceID",
    Component: ViewEndUserInvoiceSOAPage,
    roles: ["ADMIN", "RMAN", "DEALER", "DEALER_RMAN"],
    text: "End User Profile",
    show: false,
    Icon: "",
  },
  {
    path: "/dealer-profile/:intPkHeadID",
    Component: ViewDealerProfilePage,
    roles: ["ADMIN"],
    text: "Dealer Profile",
    show: false,
    Icon: "",
  },

  // Reports
  {
    path: "/reports",
    Component: ChangePasswordPage,
    roles: ["ADMIN"],
    text: "Reports",
    show: true,
    Icon: AiOutlineFileText,
    children: [
      {
        path: "/reports/dealer-payment-reports",
        Component: DealerPaymentsReportPage,
        roles: ["ADMIN"],
        text: "Dealer Payments",
        show: true,
        Icon: AiOutlineFileText,
      },
      {
        path: "/reports/dealer-ledger-reports",
        Component: DealerLedgerReportPage,
        roles: ["ADMIN"],
        text: "Dealer Ledger",
        show: true,
        Icon: AiOutlineFileText,
      },
      {
        path: "/reports/company-retails-sales",
        Component: CompanyRetailsSalesChartsPage,
        roles: ["ADMIN"],
        text: "Retail Sales",
        show: true,
        Icon: IoBarChartSharp,
      },
    ],
  },
];
export const AppRoutes = () => {
  const role = getUserType();

  const filterRoutes = (routes: Route[]): Route[] => {
    return routes
      .filter((route) => route.roles.includes(role))
      .map((route) => ({
        ...route,
        children: route.children ? filterRoutes(route.children) : [],
      }));
  };

  return filterRoutes(routesList);
};
