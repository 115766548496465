import React, { useEffect, useState } from "react";
import { Col, Row, Typography, MenuProps, Dropdown, Switch } from "antd";
import { MdContentCopy } from "react-icons/md";
import CopyToClipboard from "react-copy-to-clipboard";
import "./../../../styles/staff/profile.css";
import { ButtonProps } from "../../../helpers/constants";
import useMultiNotification from "../../../hooks/useNotification";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { formatDateForJustMonthDayAndYear } from "../../../helpers/date";
import { MdEdit } from "react-icons/md";
import { MenuOutlined } from "@ant-design/icons";
import { getUserType } from "../../../helpers/userDetails";
import TailSpinLoader from "../../Items/Loaders/TailSpinLoader";
import UpdateEndUserModalComponent from "../../Modals/UpdateEndUserModal";
import UpdateEndUserOntIDModalComponent from "../../Modals/UpdateEndUserOntIDModal";
import UpdateFixedDiscountOnUserModalComponent from "../../Modals/UpdateFixedDiscountOnUserModal";
import RechargeModalComponent from "../../Modals/RechargeModal";
import VariableRechargeModalComponent from "../../Modals/VariableRechargeModal";
import ChangePackageModalComponent from "../../Modals/ChangePackageModal";
import MeshBillButton from "../../Items/Button/Index";
import UpdateEndUserStatusModal from "../../Modals/UpdateEndUserStatusModal";
import UpdateStaticIPModalForEndUser from "../../Modals/UpdateStaticIPModalForEndUser";
import UpdateExpiryDateModal from "../../Modals/UpdateExpiryDateModal";

interface UserSingleRowSimpleProp {
  label: string;
  value: string;
  isBorderBottom: boolean;
}
const UserSingleRowSimple: React.FC<UserSingleRowSimpleProp> = ({
  label,
  value,
  isBorderBottom = true,
}) => {
  return (
    <Col
      span={24}
      className={`profile-card-single-row ${
        isBorderBottom === true ? "border-bottom-1px" : ""
      }`}
    >
      <div className="values-container">
        <Typography className="user-label-primary">{label}</Typography>
        <Typography className="user-value"> {value}</Typography>
      </div>
    </Col>
  );
};
const ViewUserProfileComponent: React.FC<EndUserProfileComponent> = ({
  intPkEndUserID,
  selectedDealer = null,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [endUser, setEndUser] = useState<EndUser | null>(null);
  const [showOntIdUpdate, setShowOntIDUpdate] = useState<boolean>(false);
  const [showUpdateExpiryDate, setShowUpdateExpiryDate] =
    useState<boolean>(false);
  const [showStaticIPUpdate, setShowStaticIPUpdate] = useState<boolean>(false);
  const [showRecharge, setShowRecharge] = useState<boolean>(false);
  const [showVariableRecharge, setShowVariableRecharge] =
    useState<boolean>(false);
  const [showChangePackage, setShowChangePackage] = useState<boolean>(false);
  const [showFixedDiscount, setShowFixedDiscount] = useState<boolean>(false);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [showUpdateStatus, setShowUpdateStatus] = useState<boolean>(false);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<SingleEndUserProfileApiProp>(
    "endUsers/getSingleEndUserProfileDetails"
  );

  useEffect(() => {
    postApiData({ intPkEndUserID, selectedDealer: selectedDealer });
  }, []);

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setEndUser(data || null);
    }
  }, [apiResponse]);

  const calculateDiscount = () => {
    if (!endUser) {
      return 0;
    }
    const { fixedDiscountAmount, onceDiscountAmount, onceDiscountExpiry } =
      endUser;
    let discount = 0;
    if (fixedDiscountAmount) {
      discount = fixedDiscountAmount;
    }
    if (
      onceDiscountAmount &&
      onceDiscountExpiry &&
      new Date(onceDiscountExpiry) > new Date()
    ) {
      discount = onceDiscountAmount;
    }
    return discount;
  };

  const calculateTotalPrice = () => {
    if (!endUser) {
      return 0;
    }
    const { salePrice, staticIPPrice } = endUser;
    let price = salePrice;
    if (staticIPPrice) {
      price = Number(price) + Number(staticIPPrice);
    }
    return price;
  };
  const getReceivAbleAmount = () => {
    if (!endUser) {
      return 0;
    }
    const price = calculateTotalPrice();
    const discount = calculateDiscount();
    return Number(price) - Number(discount);
  };
  const handleClose = (isReload: boolean) => {
    setShowUpdateExpiryDate(false);
    setShowUpdate(false);
    setShowOntIDUpdate(false);
    setShowRecharge(false);
    setShowFixedDiscount(false);
    setShowChangePackage(false);
    setShowVariableRecharge(false);
    setShowUpdateStatus(false);
    setShowStaticIPUpdate(false);
    if (isReload) {
      postApiData({ intPkEndUserID });
    }
  };
  const userProfileOptions: MenuProps["items"] = [
    {
      key: "SINGLE_RECHARGE",
      label: (
        <Typography className="general-label-class">Single Recharge</Typography>
      ),
    },
    {
      key: "VARIABLE_RECHARGE",
      label: (
        <Typography className="general-label-class">
          Variable Recharge
        </Typography>
      ),
    },
    {
      key: "CHANGE_PACKAGE",
      label: (
        <Typography className="general-label-class">Change Package</Typography>
      ),
    },
  ];
  if (getUserType() === "ADMIN") {
    userProfileOptions.push(
      {
        key: "OFFER_FIXED_DISCOUNT",
        label: (
          <Typography className="general-label-class">
            Offer Fixed Discount
          </Typography>
        ),
      },
      {
        key: "UPDATE_EXPIRY_DATE",
        label: (
          <Typography className="general-label-class">
            Update Expiry Date
          </Typography>
        ),
      }
    );
  }
  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "SINGLE_RECHARGE":
        setShowRecharge(true);
        break;
      case "VARIABLE_RECHARGE":
        setShowVariableRecharge(true);
        break;
      case "CHANGE_PACKAGE":
        setShowChangePackage(true);
        break;
      case "OFFER_FIXED_DISCOUNT":
        setShowFixedDiscount(true);
        break;
      case "UPDATE_EXPIRY_DATE":
        setShowUpdateExpiryDate(true);
        break;
    }
  };
  if (apiLoading) {
    return <TailSpinLoader />;
  }
  return (
    <>
      {contextHolder}
      {endUser && (
        <>
          {showUpdate === true && (
            <UpdateEndUserModalComponent
              show={showUpdate}
              handleClose={handleClose}
              endUser={endUser}
            />
          )}
          {showOntIdUpdate === true && (
            <UpdateEndUserOntIDModalComponent
              show={showOntIdUpdate}
              handleClose={handleClose}
              endUser={endUser}
            />
          )}
          {showUpdateExpiryDate === true && (
            <UpdateExpiryDateModal
              show={showUpdateExpiryDate}
              handleClose={handleClose}
              endUser={endUser}
            />
          )}
          {showFixedDiscount === true && (
            <UpdateFixedDiscountOnUserModalComponent
              show={showFixedDiscount}
              handleClose={handleClose}
              endUser={endUser}
            />
          )}
          {showRecharge === true && (
            <RechargeModalComponent
              show={showRecharge}
              handleClose={handleClose}
              endUser={endUser}
              amountAfterDiscount={getReceivAbleAmount()}
              discountAmount={calculateDiscount()}
            />
          )}
          {showVariableRecharge === true && (
            <VariableRechargeModalComponent
              show={showVariableRecharge}
              handleClose={handleClose}
              endUser={endUser}
              amountAfterDiscount={getReceivAbleAmount()}
              discountAmount={calculateDiscount()}
            />
          )}
          {showChangePackage === true && (
            <ChangePackageModalComponent
              show={showChangePackage}
              handleClose={handleClose}
              endUser={endUser}
              amountAfterDiscount={getReceivAbleAmount()}
              discountAmount={calculateDiscount()}
            />
          )}
          {showUpdateStatus === true && (
            <UpdateEndUserStatusModal
              show={showUpdateStatus}
              handleClose={handleClose}
              intPkEndUserID={endUser.intPkEndUserID}
              enable={endUser.enable}
            />
          )}
          {showStaticIPUpdate === true && (
            <UpdateStaticIPModalForEndUser
              show={showStaticIPUpdate}
              handleClose={handleClose}
              intPkEndUserID={endUser.intPkEndUserID}
              staticIP={endUser.staticIP}
            />
          )}
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
              marginTop: "20px",
            }}
            span={24}
          >
            <MeshBillButton
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                setShowUpdate(true);
              }}
              text="Update Profile"
            />
          </Col>

          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
              marginTop: "20px",
            }}
            span={24}
          >
            <MeshBillButton
              backgroundColor={ButtonProps.background.YELLOW}
              borderColor={ButtonProps.background.YELLOW}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                setShowRecharge(true);
              }}
              text="Recharge"
            />
          </Col>
          <Col lg={12} xl={12} xxl={12} sm={24} md={12}>
            <Row className="staff-profile-card-container">
              <Col
                span={24}
                className="staff-details-title-label-container border-bottom-1px"
              >
                <Typography className="staff-profile-card-title">
                  User Profile
                </Typography>
                <Dropdown
                  menu={{
                    items: userProfileOptions,
                    onClick: handleMenuClick,
                  }}
                  placement="bottomRight"
                >
                  <MenuOutlined className="cursor-pointer" />
                </Dropdown>
              </Col>
              <UserSingleRowSimple
                label="Full Name"
                value={`${endUser.firstName} ${endUser.lastName}`}
                isBorderBottom={true}
              />
              <UserSingleRowSimple
                label="Address"
                value={`${endUser.address} `}
                isBorderBottom={true}
              />
              <UserSingleRowSimple
                label="Phone Number"
                value={`${endUser.phoneNumber}`}
                isBorderBottom={true}
              />
              <UserSingleRowSimple
                label="NIC Number"
                value={`${endUser.nicNumber ? endUser.nicNumber : "---"}`}
                isBorderBottom={true}
              />
              {/* UserName */}
              {/* <UserSingleRowSimple
                label="UserName"
                value={`${endUser.username}`}
                isBorderBottom={true}
              /> */}
              <Col
                span={24}
                className={`profile-card-single-row border-bottom-1px`}
              >
                <div className="values-container">
                  <Typography className={`user-label-primary `}>
                    UserName
                  </Typography>
                  <Typography className="user-value">
                    {endUser.username}

                    <CopyToClipboard
                      text={endUser.username || ""}
                      onCopy={() => {}}
                    >
                      <MdContentCopy
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className="cursor-pointer "
                      />
                    </CopyToClipboard>
                  </Typography>
                </div>
              </Col>
              {/* User Name End */}

              {/* Password */}
              {/* <UserSingleRowSimple
                label="Password"
                value={`${endUser.password}`}
                isBorderBottom={true}
              /> */}

              <Col
                span={24}
                className={`profile-card-single-row border-bottom-1px`}
              >
                <div className="values-container">
                  <Typography className={`user-label-primary `}>
                    Password
                  </Typography>
                  <Typography className="user-value">
                    {endUser.password}

                    <CopyToClipboard
                      text={endUser.password || ""}
                      onCopy={() => {}}
                    >
                      <MdContentCopy
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className="cursor-pointer "
                      />
                    </CopyToClipboard>
                  </Typography>
                </div>
              </Col>
              {/* Password End */}

              <UserSingleRowSimple
                label="Package"
                value={`${endUser.packageName}`}
                isBorderBottom={true}
              />
              {/* <UserSingleRowSimple
                label=""
                value={`${endUser.ontID ? endUser.ontID : "---"}`}
                isBorderBottom={true}
              /> */}

              {/* ONT ID */}
              <Col
                span={24}
                className={`profile-card-single-row border-bottom-1px`}
              >
                <div className="values-container">
                  <Typography
                    className={`user-label-primary ${
                      endUser.ontID === null ? "blink-label" : ""
                    }`}
                  >
                    ONT ID
                  </Typography>
                  <Typography className="user-value">
                    {endUser.ontID ? endUser.ontID : "---"}

                    <MdEdit
                      style={{ marginLeft: "10px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowOntIDUpdate(true);
                      }}
                      className="cursor-pointer"
                    />
                  </Typography>
                </div>
              </Col>
              {/* ONT ID End */}

              {/* STATIC IP */}
              <Col
                span={24}
                className={`profile-card-single-row border-bottom-1px`}
              >
                <div className="values-container">
                  <Typography className={`user-label-primary `}>
                    Static IP
                  </Typography>
                  <Typography className="user-value">
                    {endUser.staticIP ? endUser.staticIP : "---"}

                    <MdEdit
                      style={{ marginLeft: "10px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowStaticIPUpdate(true);
                      }}
                      className="cursor-pointer"
                    />
                  </Typography>
                </div>
              </Col>
              {/* STATIC IP End */}

              <UserSingleRowSimple
                label="Expiry Date"
                value={`${formatDateForJustMonthDayAndYear(
                  endUser.expiryDate
                )}`}
                isBorderBottom={true}
              />
              <UserSingleRowSimple
                label="Recharge Date"
                value={`${formatDateForJustMonthDayAndYear(
                  endUser.lastRechargeDate
                )}`}
                isBorderBottom={true}
              />

              <Col
                span={24}
                className={`profile-card-single-row border-bottom-1px`}
              >
                <div className="values-container">
                  <Typography className={`user-label-primary `}>
                    Status
                  </Typography>
                  <Typography className="user-value">
                    <Switch
                      value={Boolean(endUser.enable)}
                      onChange={(e) => {
                        setShowUpdateStatus(true);
                      }}
                    />
                  </Typography>
                </div>
              </Col>
              <Col span={24} className={`profile-card-single-row `}>
                <div className="values-container">
                  <Typography className={`user-label-primary `}>
                    Available Balance
                  </Typography>
                  <Typography className="balance-available-label">
                    {endUser.freeCreditNote ? endUser.freeCreditNote.amount : 0}
                  </Typography>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={12} xl={12} xxl={12} sm={24} md={12}>
            <Row className="staff-profile-card-container">
              <UserSingleRowSimple
                label="Package Price"
                value={`${endUser.salePrice.toLocaleString()}/-`}
                isBorderBottom={true}
              />
              {endUser.staticIPPrice && (
                <>
                  {" "}
                  <UserSingleRowSimple
                    label="Static IP Price"
                    value={`${endUser.staticIPPrice.toLocaleString()}`}
                    isBorderBottom={true}
                  />
                  <UserSingleRowSimple
                    label="Static IP"
                    value={`${endUser.staticIP ? endUser.staticIP : "---"}`}
                    isBorderBottom={true}
                  />
                </>
              )}

              <UserSingleRowSimple
                label="Total Package Price"
                value={`${calculateTotalPrice().toLocaleString()}/-`}
                isBorderBottom={true}
              />

              {/* Discount */}
              <Col
                span={24}
                className={`profile-card-single-row border-bottom-1px`}
              >
                <div className="values-container">
                  <Typography className="user-label-primary">
                    Discount
                  </Typography>
                  <Typography className="balance-minus-label">
                    {calculateDiscount().toLocaleString()}/-
                  </Typography>
                </div>
              </Col>
              {/* Discount End */}

              <UserSingleRowSimple
                label="Receivable Amount"
                value={`${getReceivAbleAmount().toLocaleString()}/-`}
                isBorderBottom={false}
              />
            </Row>
          </Col>
        </>
      )}
    </>
  );
};
export default ViewUserProfileComponent;
