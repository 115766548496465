import { useEffect, useState } from "react";
import "./../../styles/uploader.css";
import useAxiosPost, { authTokenString } from "../../hooks/useAxiosPost";
import { Upload, Button, Row, Alert, message, Select } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { usePapaParse } from "react-papaparse";
import * as XLSX from "xlsx";

import type { UploadFile } from "antd/es/upload/interface";

import useMultiNotification from "../../hooks/useNotification";
import { socket } from "../../socketService";

import { useNavigate } from "react-router-dom";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import ImportUsersThroughFileTable from "../Tables/ImportUsersThroughFileTable";
import moment from "moment";
import _ from "lodash";
import { generateRandomPassword } from "../../helpers/generateRandomPassword";
import CreateDealersAtImportTimeTable from "../Tables/CreateDealersAtImportTimeTable";
import CreatePackagesTable from "../Tables/CreatePackagesTable";
const ImportUsersThroughFile = () => {
  const navigate = useNavigate();
  const [fileType, setFileType] = useState<string | undefined>(undefined);
  const [data, setData] = useState<UsersCsvData[]>([]);
  const [dealersToSend, setDealersToSend] = useState<BulkDealerForApi[]>([]);
  const { readString } = usePapaParse();
  const [staff, setStaffProfile] = useState<StaffListType | null>(null);
  const [objectifiedDealers, setObjectifiedDealers] = useState<any>({});
  const [objectifiedEndUsers, setObjectifiedEndUsers] = useState<any>({});
  const { openNotification, contextHolder } = useMultiNotification();
  const [getPackagesApi, packagesApiResponse] = useAxiosPost<ResponseProp>(
    "packages/getPackagesByOwner"
  );
  const [isAlreadyImporting, setIsAlreadyImporting] = useState<boolean>(true);
  const [isPackagesCreated, setIsPackagesCreated] = useState<boolean>(false);
  const [getDealersApi, dealersApiResponse] = useAxiosPost<ResponseProp>(
    "dealers/getObjectifiedCompanyDealers"
  );
  const [getEndUsersApi, endUsersApiResponse] = useAxiosPost<ResponseProp>(
    "endUsers/getObjectifiedEndUsersByCompany"
  );
  const [getImportStatusApi, importStatusApiResponse] =
    useAxiosPost<ResponseProp>("endUsers/checkIFTheresAPendingRequestToImport");

  const [
    postBulkApi,
    bulkApiResponse,
    bulkApiError,
    bulkApiLoading,
    bulkApiMessage,
    bulkApiErrorCount,
  ] = useAxiosPost<BulkPackagesCreationApi>("packages/createPackagesInBulk");

  const [
    postBulkDealerCreation,
    bulkDealerCreationApiResponse,
    bulkDealerApiError,
    bulkDealerApiLoading,
    bulkDealerApiMessage,
    bulkDealerApiErrorCount,
  ] = useAxiosPost<BulkDealerCreationApiProp>("dealers/createDealersInBulk");

  const [dealers, setDealers] = useState<BulkDealerRecord[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("auth/getMyProfile");
  const [packages, setPackages] = useState<SinglePackage[]>([]);
  const [bulkPackages, setBulkPackages] = useState<CreateBulkPackageRecord[]>(
    []
  );
  const [objectifiedPackages, setObjectifiedPackages] = useState<any>({});
  const [packageOptions, setPackageOptions] = useState<MeshBillSelectOptions[]>(
    []
  );
  const [isUploading, setIsUploading] = useState<boolean>(true);
  // const [isUploadedFromFile, setIsUploadedFromFile] = useState<boolean>(false);
  // const [isDealerFormatted, setIsDealerFormatted] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setStaffProfile(data);
    }
  }, [apiResponse]);

  useEffect(() => {
    if (bulkApiResponse !== null) {
      const { message } = bulkApiResponse;
      setIsUploading(false);
      // setStep(2);
      getPackagesApi({});
      setIsPackagesCreated(true);
      openNotification(`success`, "Success", `Packages Created Successfully`);
    }
  }, [bulkApiResponse]);
  useEffect(() => {
    if (bulkApiError !== null && bulkApiErrorCount !== 0) {
      openNotification(`error`, "API Failed", bulkApiError);
      setIsUploading(false);
    }
  }, [bulkApiError, bulkApiErrorCount]);

  useEffect(() => {
    if (bulkDealerApiError !== null && bulkDealerApiErrorCount !== 0) {
      openNotification(`error`, "API Failed", bulkDealerApiError);
      setIsUploading(false);
    }
  }, [bulkDealerApiError, bulkDealerApiErrorCount]);
  useEffect(() => {
    if (bulkDealerCreationApiResponse !== null) {
      const { message } = bulkDealerCreationApiResponse;
      setIsUploading(false);
      setStep(3);
      openNotification(`success`, "Success", `Dealers Created Successfully`);
    }
  }, [bulkDealerCreationApiResponse]);

  useEffect(() => {
    if (dealersApiResponse !== null) {
      const data: any = dealersApiResponse.responseData;
      setObjectifiedDealers(data);
    }
  }, [dealersApiResponse]);
  useEffect(() => {
    if (endUsersApiResponse !== null) {
      const data: any = endUsersApiResponse.responseData;
      setObjectifiedEndUsers(data);
    }
  }, [endUsersApiResponse]);
  useEffect(() => {
    if (importStatusApiResponse !== null) {
      const responseData: any = importStatusApiResponse.responseData;
      // console.log({ responseData });
      if (responseData.data) {
        setIsAlreadyImporting(true);
      } else {
        setIsAlreadyImporting(false);
      }
      setIsUploading(false);
    }
  }, [importStatusApiResponse]);
  useEffect(() => {
    getPackagesApi({});
    postApiData({});
    getDealersApi({});
    getEndUsersApi({});
    getImportStatusApi({});
    socket.emit(
      "join",
      {
        userName: localStorage.getItem("username") || "client",
        client: "web",
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    socket.on(`errorUploadingUsers`, (data) => {
      // console.log({ data });
      openNotification(
        `error`,
        "Operation Failed",
        data.message || "Error While Uploading"
      );
      setIsUploading(false);
    });
    socket.on(`bulkUserUploaded`, (data) => {
      openNotification(
        `success`,
        "Success",
        data.message || "Users Uploaded Successfully"
      );
      setTimeout(() => {
        navigate("/");
      }, 2500);
    });
  }, []);
  const splitFullName = (fullName: string) => {
    let firstName = "";
    let lastName = "";
    // console.log({ fullName });
    if (!fullName.trim()) {
      return { firstName, lastName };
    }

    const [first, ...rest] = fullName.split(" ");
    firstName = first;
    lastName = rest.join(" ").trim();

    if (!lastName) {
      lastName = firstName;
    }

    return { firstName, lastName };
  };
  const expiryDateGetter = (date: string) => {
    if (
      !date ||
      date === "" ||
      date === " " ||
      date === "0000-00-00" ||
      date === "0000-00-00 00:00:00" ||
      date === "N/A"
    ) {
      return {
        expiryDateToDisplay: moment(new Date()).format("YYYY-MM-DD"),
        expiryDate: moment(new Date()).format("YYYY-MM-DD"),
      };
    }
    return {
      expiryDateToDisplay: moment(date, "DD MMM YYYY HH:mm:ss").format(
        "YYYY-MM-DD"
      ),
      expiryDate: moment(date, "DD MMM YYYY HH:mm:ss").format("YYYY-MM-DD"),
    };
  };

  useEffect(() => {
    if (packagesApiResponse !== null) {
      const _data: any = packagesApiResponse.responseData;
      const objectifiedPackages: any = {};
      const packageOptions: MeshBillSelectOptions[] = _data.map(
        (item: any): MeshBillSelectOptions => {
          objectifiedPackages[item.packageName] = item;
          return {
            value: item.intPkPackageID,
            label: item.packageName,
          };
        }
      );

      // console.log({ _data });
      setPackages(_data || []);
      setPackageOptions(packageOptions);
      setObjectifiedPackages(objectifiedPackages);
      if (isPackagesCreated === true && staff) {
        // console.log({ data: data.length });
        formatDealersToShow(2, data, staff, objectifiedPackages);
        setIsUploading(false);
      }
    }
  }, [packagesApiResponse]);
  const handleUploadUsers = (users: UsersCsvData[]) => {
    const _dealersToSend = dealersToSend.map((item) => {
      return {
        ...item,
        assignedPackages: item.assignedPackages.map((_item: any) => {
          const found = objectifiedPackages[_item];
          return {
            ...found,
          };
        }),
      };
    });
    // console.log({ total: users.length, users, dealersToSend, _dealersToSend });
    const dataForSize = {
      users: users,
      // dealers: _dealersToSend,
    };
    const stringifiedData = JSON.stringify(dataForSize);
    const blob = new Blob([stringifiedData], { type: "application/json" }).size;
    // console.log({ blob });
    // return;
    setIsUploading(true);
    socket.emit("uploadUsersThroughFile", {
      users: users,
      dealers: _dealersToSend,
      authToken: authTokenString,
    });
  };
  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.href = "/sample-users.csv";
    link.setAttribute("download", "sample-users.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getActiveTable = (step: number) => {
    switch (step) {
      case 1:
        return (
          <CreatePackagesTable
            packages={bulkPackages}
            isLoading={isUploading}
            isUploading={isUploading}
            handleUploadPackages={handleUploadPackages}
          />
        );
        break;
      case 2:
        return (
          <CreateDealersAtImportTimeTable
            dealers={dealers}
            handleCreateDealers={handleCreateDealers}
            // isUploading={isUploading}
            // packageOptions={packageOptions}
            // handleUserSkip={handleUserSkip}
            // handleUploadUsers={handleUploadUsers}
          />
        );
        break;
      case 3:
        return (
          <ImportUsersThroughFileTable
            users={data}
            isUploading={isUploading}
            packageOptions={packageOptions}
            dealers={dealersToSend}
            // handleUserSkip={handleUserSkip}
            handleUploadUsers={handleUploadUsers}
          />
        );
        break;
    }
  };

  const handleUploadPackages = (packages: CreateBulkPackageRecord[]) => {
    // console.log({ packages });
    if (packages.length < 1 && staff) {
      formatDealersToShow(2, data, staff, objectifiedPackages);
      return;
    }
    setIsUploading(true);
    const packagesToSend: BulkPackageRecord[] = packages.map((item) => {
      return {
        salePrice: Number(item.salePrice),
        packageName: item.packageName,
        poolName: item.poolName ? item.poolName : null,
        speedUp: Number(item.speedUnit),
        speedDown: Number(item.speedUnit),
        speedAbbreviation: item.speedAbbreviation,
        packageDuration: item.packageDuration,
        isVariableRechargeAllowed: Number(item.isVariableRechargeAllowed),
        profileName: item.profileName ? item.profileName : null,
        isCreateDynamicQueue: Number(item.isCreateDynamicQueue),
        isAlreadyCreated: item.isAlreadyCreated,
      };
    });
    postBulkApi({ packages: packagesToSend });
  };

  const formatPackagesToShow = (
    stepToSet: number,
    formattedData: UsersCsvData[]
  ) => {
    const bulkPackages: CreateBulkPackageRecord[] = _.uniqBy(
      formattedData,
      "selectedPackage"
    )
      .filter((item) => item.selectedPackage !== "")
      .map((item: UsersCsvData, index: number): CreateBulkPackageRecord => {
        const isAlready = objectifiedPackages[item.selectedPackage];
        if (isAlready) {
          // console.log({ isAlready });
        }
        return {
          salePrice: 0,
          packageName: item.selectedPackage,
          poolName: null,
          profileName: null,
          speedUp: 0,
          speedDown: 0,
          speed: 0,
          speedUnit: "0",
          speedAbbreviation: "",
          packageDuration: "THIRTY_DAYS",
          isVariableRechargeAllowed: 1,
          isCreateDynamicQueue: 0,
          isAlreadyCreated: isAlready ? true : false,
          dealer: item.dealer,
        };
      });
    setBulkPackages(bulkPackages);
    setStep(stepToSet);
  };

  // const formatDealersToShow = (
  //   stepToSet: number,
  //   formattedData: UsersCsvData[],
  //   staff: StaffListType,
  //   _objectifiedPackages: any
  // ) => {

  //   const dealers: BulkDealerRecord[] = _.uniqBy(formattedData, "dealer")
  //     .filter((item) => item.dealer !== "")
  //     .map((item: UsersCsvData, index: number): BulkDealerRecord => {
  //       const isAlready = objectifiedDealers[item.dealer];
  //       if (isAlready) {
  //         console.log({ isAlready });
  //       }

  //       return {
  //         key: index,
  //         firstName: item.dealer,
  //         lastName: item.dealer,
  //         email: null,
  //         phoneNumber: null,
  //         staffUserName: item.dealer,
  //         password: generateRandomPassword(),
  //         address: staff.address,
  //         isCreditAllowed: 0,
  //         creditLimit: 0,
  //         isReadyToCreate: false,
  //         isAlreadyCreated: isAlready ? true : false,
  //       };
  //     });
  //   console.log({
  //     dealers: dealers,
  //   });
  //   setDealers(dealers);
  //   setStep(stepToSet);
  // };

  const formatDealersToShow = (
    stepToSet: number,
    formattedData: UsersCsvData[],
    staff: StaffListType,
    _objectifiedPackages: any
  ) => {
    // Preprocess the records once to create a map of dealers and their unique packages
    const dealerPackageMap = preprocessRecords(formattedData);

    const dealers: BulkDealerRecord[] = _.uniqBy(formattedData, "dealer")
      .filter((item) => item.dealer !== "")
      .map((item: UsersCsvData, index: number): BulkDealerRecord => {
        const isAlready = objectifiedDealers[item.dealer];
        if (isAlready) {
          // console.log({ isAlready });
        }

        // Get unique selectedPackage values for the current dealer
        const uniquePackages = getUniquePackagesByDealer(
          item.dealer,
          dealerPackageMap
        );
        // console.log({ dealer: item.dealer, uniquePackages });
        // console.log({ uniquePackages });
        return {
          key: index,
          firstName: item.dealer,
          lastName: item.dealer,
          email: null,
          phoneNumber: null,
          staffUserName: item.dealer,
          password: generateRandomPassword(),
          address: staff.address,
          isCreditAllowed: 0,
          creditLimit: 0,
          isReadyToCreate: false,
          isAlreadyCreated: isAlready ? true : false,
          assignedPackages: uniquePackages,
        };
      });

    // console.log({
    //   dealers: dealers,
    // });

    setDealers(dealers);
    setStep(stepToSet);
  };

  // Preprocessing function for creating the dealerPackageMap
  function preprocessRecords(records: any) {
    const dealerPackageMap = new Map();

    records.forEach((record: any) => {
      const { dealer, selectedPackage } = record;

      // If the dealer doesn't exist in the map, initialize with a Set
      if (!dealerPackageMap.has(dealer)) {
        dealerPackageMap.set(dealer, new Set());
      }

      // Add the selectedPackage to the Set if it's defined
      if (selectedPackage) {
        dealerPackageMap.get(dealer).add(selectedPackage);
      }
    });

    return dealerPackageMap;
  }

  // Function to get unique packages for a specific dealer using the preprocessed map
  function getUniquePackagesByDealer(dealerString: any, dealerPackageMap: any) {
    // Retrieve the Set of unique packages for the dealer and convert it to an array
    return Array.from(dealerPackageMap.get(dealerString) || []);
  }

  const handleCreateDealers = (dealers: BulkDealerRecord[]) => {
    // console.log({ dealers });
    const dealersToSend: BulkDealerForApi[] = dealers.map((item) => {
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        staffUserName: item.staffUserName,
        password: item.password,
        address: item.address,
        isCreditAllowed: Number(item.isCreditAllowed),
        creditLimit: Number(item.creditLimit),
        email: item.email ? item.email : null,
        phoneNumber: item.phoneNumber ? item.phoneNumber : null,
        assignedPackages: item.assignedPackages,
      };
    });
    formulateUsersToDisplay(dealersToSend);

    // postBulkDealerCreation({ dealers: dealersToSend });
  };
  const formulateUsersToDisplay = (dealersToSend: BulkDealerForApi[]) => {
    const objectifiedDealersToSend: any = {};
    for (const dealer of dealersToSend) {
      objectifiedDealersToSend[dealer.staffUserName] = dealer;
    }
    const dataToSet: UsersCsvData[] = [];
    for (const _item of data) {
      const isFound = objectifiedDealersToSend[_item.dealer];
      dataToSet.push({
        ..._item,
        isDealerCreated: isFound ? true : false,
      });
    }
    setData(dataToSet);
    setDealersToSend(dealersToSend);
    setStep(3);
  };
  const handleUpload = (file: UploadFile) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const csvData = e.target?.result?.toString() || "";
      readString(csvData, {
        header: true,
        complete: (results) => {
          const data = results.data;
          formUploader(data);
        },
        error: (error) => {},
      });
    };
    reader.readAsText(file as unknown as Blob);
    return false;
  };

  const handleUploadExcel = (file: UploadFile): boolean => {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const arrayBuffer = event.target?.result;

      if (arrayBuffer) {
        // Convert ArrayBuffer to Uint8Array for XLSX to process
        const data = new Uint8Array(arrayBuffer as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });

        // Assuming you want to read the first sheet
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convert sheet to JSON format
        const sheetData = XLSX.utils.sheet_to_json(sheet);
        // console.log(sheetData); // Use this data as needed
        formUploader(sheetData);
        // message.success(`${file.name} file uploaded successfully.`);
      }
    };
    const actualFile = file.originFileObj || file;

    // Read the file as ArrayBuffer
    if (actualFile) {
      reader.readAsArrayBuffer(actualFile as Blob);
    } else {
      message.error("Failed to upload file.");
    }

    // Prevent default upload behavior
    return false;
  };
  const formUploader = (data: any[]) => {
    let isError: boolean = false;
    if (!staff) {
      return;
    }
    // console.log({ data });
    const formattedData = data.map((row: any, index: number) => {
      const { firstName, lastName } = splitFullName(row.Name?.toString() || "");

      const selectedPackage = objectifiedPackages[row.Package];
      if (!selectedPackage) {
        // console.log(row);
      }
      const isAlreadyCreated = objectifiedEndUsers[row.Username];
      // console.log(`Salesperson: ${row?.Salesperson}`);
      if (
        staff.staffUserName.toLowerCase() ===
        row?.Salesperson?.toString()?.toLowerCase()
      ) {
        isError = true;
      }
      const { expiryDateToDisplay, expiryDate } = expiryDateGetter(
        row["Expiration Date"]
      );
      // console.log(`${row.Username}`, row["Expiration Date"]);
      return {
        isReadyToCreate: false,
        isDealerCreated: true,
        isAlreadyCreated: isAlreadyCreated ? true : false,
        key: index,
        firstName: firstName || "",
        lastName: lastName || "",
        username: row.Username?.toString() || "",
        password: row.Password?.toString() || "",
        phoneNumber: row.Mobile?.toString() || "",
        address: row.Address?.toString() || "",
        ontID: row.ontID?.toString() || "",
        enable: "1",
        expiryDateToDisplay: expiryDateToDisplay,
        expiryDate: expiryDate,
        dealer: row.Salesperson?.toString() || "",
        remainingBalance: 0,
        advanceBalance: 0,
        intPkPackageID: selectedPackage ? selectedPackage.intPkPackageID : "",
        nicNumber: row.NID?.toString() || "",
        selectedPackage: selectedPackage
          ? selectedPackage.packageName
          : row.Package,
      };
    });

    if (isError) {
      openNotification(
        `error`,
        "Operation Failed",
        `You Have A Dealer UserName That matched Admin Please empty that!!!`
      );
    } else {
      setData(formattedData);
      // console.log({ formattedData });
      formatPackagesToShow(1, formattedData);
    }
  };

  if (isUploading) {
    return (
      <>
        {contextHolder}
        <TailSpinLoader />;
      </>
    );
  }
  const showUploader = (fileType: string | undefined) => {
    if (fileType === "csv") {
      return (
        <Upload
          beforeUpload={handleUpload}
          showUploadList={false}
          accept=".csv"
          className="csv-upload-button"
        >
          <Button
            disabled={step >= 1}
            className="csv-upload-button"
            icon={<UploadOutlined />}
          >
            Upload CSV
          </Button>
        </Upload>
      );
    }
    if (fileType === "excel") {
      return (
        <Upload
          className="csv-upload-button"
          beforeUpload={handleUploadExcel}
          disabled={step >= 1}
          // fileList={fileList}
          // onRemove={() => setFileList([])}
          showUploadList={false} // Set to true if you want to show the uploaded file list
          accept=".xlsx, .xls"
        >
          <Button className="csv-upload-button" icon={<UploadOutlined />}>
            Upload Excel (.xlsx)
          </Button>
        </Upload>
      );
    }
    return <></>;
  };
  return (
    <>
      {contextHolder}

      <Row
        className="full-width-row mb-20"
        style={{
          display: "flex",
          justifyContent: "center",
          minWidth: step >= 3 ? "1331px" : "100%",
        }}
      >
        <div className="csv-uploader-container">
          {isAlreadyImporting === true ? (
            <Alert
              message="Already Importing"
              description="Users are already being imported. Please wait for the current import to complete before starting a new one."
              type="info"
              showIcon
            />
          ) : (
            <>
              <Select
                placeholder="Select a file type"
                onChange={(e) => setFileType(e)}
                disabled={step >= 1}
                style={{ width: 200 }}
                value={fileType}
              >
                <Select.Option value="csv">CSV</Select.Option>
                <Select.Option value="excel">Excel</Select.Option>
              </Select>
            </>
          )}
          {showUploader(fileType)}

          {/* <Button
            className="csv-download-button"
            icon={<DownloadOutlined />}
            onClick={handleDownloadSample}
            style={{ marginTop: "16px" }}
          >
            Download Sample File
          </Button> */}
        </div>
      </Row>

      <Row className="full-width-row ">
        {/* {isDealerFormatted === true && (
          <CreateDealersAtImportTimeTable
            dealers={dealers}
            // isUploading={isUploading}
            // packageOptions={packageOptions}
            // handleUserSkip={handleUserSkip}
            // handleUploadUsers={handleUploadUsers}
          />
        )}
        {isUploadedFromFile === true && (
          <ImportUsersThroughFileTable
            users={data}
            isUploading={isUploading}
            packageOptions={packageOptions}
            // handleUserSkip={handleUserSkip}
            handleUploadUsers={handleUploadUsers}
          />
        )} */}
        {getActiveTable(step)}
      </Row>
    </>
  );
};
export default ImportUsersThroughFile;
